import {API, instance} from "../const/api";

export async function UploadImage(foramData, Calback) {

    await instance.post(API.uploadImage, foramData, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function MediaRemove(id, Calback) {

    await instance.post(API.mediaremove + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}





export async function UpdateProduct(formData, Calback,ProgressEvent) {

    await instance.post(API.product, formData,{
        timeout:300000,
        onUploadProgress: progressEvent => ProgressEvent(progressEvent),
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetProduct( param,Calback) {
    await instance.get(API.product,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function DeleteProduct(id, Calback) {

    await instance.delete(API.product + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function UpdateNews(formData, Calback,ProgressEvent) {

    await instance.post(API.news, formData,{
        timeout:300000,
        onUploadProgress: progressEvent => ProgressEvent(progressEvent),
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetNews( param,Calback) {
    await instance.get(API.news,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function DeleteNews(id, Calback) {

    await instance.delete(API.news + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function UpdateFaq(formData, Calback,ProgressEvent) {

    await instance.post(API.faq, formData,{
        timeout:300000,
        onUploadProgress: progressEvent => ProgressEvent(progressEvent),
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetFaq( param,Calback) {
    await instance.get(API.faq,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function DeleteFaq(id, Calback) {

    await instance.delete(API.faq + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetContact( param,Calback) {
    await instance.get(API.contact,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function GetSetting( param,Calback) {
    await instance.get(API.setting,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function UpdateSetting(formData, Calback,ProgressEvent) {

    await instance.post(API.setting, formData,{
        onUploadProgress: progressEvent => ProgressEvent(progressEvent),
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}