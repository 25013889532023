<template>
  <v-container class="custom-container container pt-8" >
    <div class="list_form_card pl-5" >
      <h2>ข้อมูลติดต่อ</h2>

      <div class="form_table mt-8" >
        <v-data-table
            :headers="headers"
            :items="resultData"
            :options.sync="options"
             sortBy="id"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.cover="{ item }">
            <img v-if="item.cover" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.cover).path" />
            <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{  dateTime(item.created_at) }}
          </template>

          <template v-slot:item.actions="{ item }">

            <div class="list-icon" >
              <v-icon
                  small
                  class="mr-2 icon-list green-icon"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  class="mr-2 icon-list red-icon"
                  @click="deleteItem(item.id)"
              >
                mdi-trash-can
              </v-icon>
            </div>


          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl, baseUrlMain} from "../const/api";
import $ from 'jquery'

import {MediaRemove, UpdateNews, DeleteNews, GetContact} from "../lib/controller";
import {dateTime, ResponseData} from "../lib/utility";

export default {
  name: 'Product',
  components: {},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อจริง', value: 'first_name',width:'100'},
      {text: 'นามสกุล', value: 'last_name',width:'100'},
      {text: 'อีเมล',key:'email',  value: 'email',width:'100'},
      {text: 'เบอรโทร',key:'tel', value: 'tel',width:'100'},
      {text: 'ข้อความ',key:'message', value: 'message',width:'100'},
      {text: 'วันที่เพิ่ม',key:'created_at',  value: 'created_at',width:'100'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    title: "",
    title_en: "",
    description: "",
    description_en: "",
    youtubePreview:"",
    id: 0,
    pin: "",
    show1: false,
    show4: false,
    image_preview: "",
    edit: "",
    nickname: "",
    cover_file: "",
    cover_file_en: "",
    cover_video_file: "",
    video: "",
    videoUrl: "",
    orders: "",
    youtube_rules: [
      v => !!v || 'กรุณาเพิ่มลิ้ง Youtube',
      v => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(v) || 'รูปแบบลิ้งไม่ถูกต้อง',
    ],
    videotype: "",
    videoType: [],
    youtube: "",
    first_name: "",
    fb: "",
    ig: "",
    x: "",
    yt: "",
    tiktok: "",
    last_name: "",
    tel: "",
    progress: 0,
    address: "",
    email: "",
    preview_list: [],
    image_list: [],
    is_admin: 0,
    is_edit: false,
    is_youtube: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    itemsPerPage: 5,
    search: '',
    loading: true,
    gallery: {},
    totalItems: 0,
    options: {},
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
  },
  methods:{
    dateTime,
    DeleteImage(index) {
      this.image_list.splice(index, 1);
      this.preview_list.splice(index, 1);
    },
    previewMultiImage: function (event) {

      //this.preview_list = [];
      //this.image_list = [];

      console.warn(event);

      event.forEach((file) => {

        var reader = new FileReader();

        reader.onload = (e) => {
          // Push the read data (base64 string) into 'preview_list'
          this.preview_list.push(e.target.result);
        };


        reader.readAsDataURL(file);
        this.image_list.push(file);
      });

    },
    async DeleteImageGallery(index, id) {


      await MediaRemove(id, (response) => {
        const {status, data} = ResponseData(response)
        switch (status) {
          case 200:
            console.warn(data);
            this.gallery.splice(index, 1);
            break;
          default:
            break;
        }

      });
    },
    baseUrlMain() {
      return baseUrlMain
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
    },
    resetEdit (data) {
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
      this.editItem(data);
      this.GetData();
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    CheckYoutube(){
      if(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(this.youtube)){
        this.youtubePreview = this.getYoutubePreview(this.youtube)
      }else{
        this.youtubePreview = "";
      }
    },
    getYoutubePreview(url) {
      const urlObj = new URL(url);
      this.youtubeCode = urlObj.searchParams.get('v');
      return "https://www.youtube.com/embed/"+urlObj.searchParams.get('v');
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      this.is_loading = true;

      let formData = new FormData();


      formData.append("title",this.title);
      formData.append("title_en",this.title_en);
      formData.append("description",this.description);
      formData.append("description_en",this.description_en);
      formData.append("orders",this.orders);
      this.image_list = this.image_list.filter(res => ((res.size / 1024) / 1024) < 2);
      this.image_list.forEach((file) => {
        formData.append('gallery_image[]', file);
      });

      if(this.id != 0){
        formData.append('is_edit',this.id)
      }

      await UpdateNews(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        this.is_loading = false;
        switch (status) {
          case 200:

            this.$swal({
              title: "สำเร็จ",
              text: this.id ? "อัพเดทสำเร็จ" : "เพิ่มข้อมูลสำเร็จ" ,
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              if(this.id){
                this.resetEdit(data);
              }else{
                this.reset();
                await this.GetData();
              }

            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      }, (UploadEvent) => {
        if (UploadEvent.total > 0) { // Avoid division by zero
          this.progress = (UploadEvent.loaded / UploadEvent.total) * 100;
        }

        // Reset progress if it's complete
        if (this.progress >= 100) {
          this.progress = 0;
        }
        console.warn(this.progress);
      })



    },

    editItem(item){


      this.id = item.id;
      this.is_edit = true;
      console.log(item)
      this.title = item.title;
      this.title_en = item.title_en;
      this.description = item.description;
      this.description_en = item.description_en;
      this.gallery = item.gallery;


    },
    async GetData(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }
      console.warn(params)
      this.loading = true;

      console.log(this)
      await GetContact(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loading = false;
        switch (status){
          case 200:
            this.resultData = data.data;
            this.totalItems = data.total;
            this.itemsPerPage = data.to;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteNews(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
.progress-loading-container{
  width: 100%;
  height: 2rem;
  background: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  border: 1px #c9c9c9 solid;
  position: relative;
}
.progress-val{
  color: #151515;
  z-index: 5;
  font-size: 1.2rem;
}
.progress-loading{
  width: 0%;
  height: 100%;
  background: #FDD44D;
}
.list-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-list{
  padding-left: 0;
  padding-right: 0;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem !important;
}

</style>
