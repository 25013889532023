<template>
  <v-container class="custom-container container" >
  <div class="list_form_card" >
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src

import moment from "moment";

export default {
  name: 'Home',
  computed: {
    moment() {
      return moment
    }
  },
  components: {},
  data: () => ({

  }),
  watch: {

  },
  methods:{

  },
  async mounted() {

  }
}
</script>
<style scoped >
.form_table{
  width: 80%;
}
.filter-container{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
</style>

