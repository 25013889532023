import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ToastPlugin from 'vue-toast-notification';
import dotenv from 'dotenv'
dotenv.config({ path: `.env.${process.env.NODE_ENV}` })
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import VCalendar from 'v-calendar';
import Vuetify from 'vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'




// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify()

Vue.use(Vuetify)



// Vuetify Object (as described in the Vuetify 2 documentation)

// use Vuetify's plugin



window.$ = window.jQuery = require('jquery')
import './assets/css/main.css'
Vue.use(VueSweetalert2);
Vue.use(DateRangePicker);
Vue.use(ToastPlugin);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
  Vue.use(VueI18n)
Vue.component("date-range-picker",DateRangePicker)
Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')


