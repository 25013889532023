
import moment from "moment";
import 'moment/locale/th';


export function dateTime(date){
    moment.locale('th');
    const formattedDate = moment(date).format('MMMM Do YYYY, H:mm:ss');
    const buddhistYear = moment(date).year() + 543;  // Calculate the BE year
    return formattedDate.replace(moment(date).year(), buddhistYear);
}


export function ResponseData(response){
    console.log(response)
    return { status:response.status,data:response.data,statusText:response.statusText }
}

export function ConvertDate(date){
    moment.locale('th');
    return  moment(date).format('LLLL');
}

export async function encryptJson(data) {
    const encoded = new TextEncoder().encode(JSON.stringify(data));
    const key = await generateKey();
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const encrypted = await crypto.subtle.encrypt(
        {
            name: "BF-ECB",
            iv: iv,
        },
        key,
        encoded
    );
    return {
        iv: Array.from(iv),
        data: Array.from(new Uint8Array(encrypted)),
    };
}
export async function decryptJson(encryptedData) {
    const key = await generateKey();
    const iv = new Uint8Array(encryptedData.iv);
    const decrypted = await crypto.subtle.decrypt(
        {
            name: "BF-ECB",
            iv: iv,
        },
        key,
        new Uint8Array(encryptedData.data)
    );
    const dec = new TextDecoder().decode(decrypted);
    return JSON.parse(dec);
}

async function generateKey() {
    // Simplified: In a real application, you'd derive this from a password or securely fetch it
    return await crypto.subtle.importKey(
        "raw",
        new TextEncoder().encode("your-secure-key-here"), // This should be secure and private!
        "BF-ECB",
        true,
        ["encrypt", "decrypt"]
    );
}

