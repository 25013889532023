<template>
  <v-container class="custom-container container pt-8" >
    <div class="list_form_card pl-5" >
      <h2>สินค้า</h2>
      <div class="form_card mt-5" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <div class="lang-list" >
            <v-checkbox
                v-model="language"
                value="th"
                label="TH"></v-checkbox>

            <v-checkbox
                v-model="language"
                value="en"
                label="EN"></v-checkbox>
          </div>


          <div v-if="language == 'en' " class="lang-list" >

            <v-checkbox
                v-model="category"
                value="us"
                label="USA"></v-checkbox>

            <v-checkbox
                v-model="category"
                value="ko"
                label="Korea"></v-checkbox>

            <v-checkbox
                v-model="category"
                value="bo"
                label="Bosnia"></v-checkbox>

            <v-checkbox
                v-model="category"
                value="fr"
                label="France"></v-checkbox>

            <v-checkbox
                v-model="category"
                value="ch"
                label="China"></v-checkbox>

          </div>


          <v-text-field
              v-model="title"
              label="ชื่อสินค้า *"
              :value="title"
              :rules="[v => !!v || 'กรุณาตั้งชื่อชื่อสินค้า']"
              required
              outlined
          ></v-text-field>

          <v-textarea
              v-model="description"
              :value="description"
              :rules="[v => !!v || 'กรุณากรอกคำอธิบาย']"
              label="คำอธิบาย *"
              outlined
          ></v-textarea>

          <div v-if="language == 'th' " >
            <v-textarea
                rows="2"
                v-model="fb"
                :value="fb"
                label="FaceBook Link"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="ig"
                :value="ig"
                label="Instargram Link"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="x"
                :value="x"
                label="X Link (Twitter)"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="shop"
                :value="shop"
                label="Shoppee Link"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="laz"
                :value="laz"
                label="Lazada Link"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="yt"
                :value="yt"
                label="Youtube Link"
            ></v-textarea>

            <v-textarea
                rows="2"
                v-model="tiktok"
                :value="tiktok"
                label="Tiktok Link"
            ></v-textarea>
          </div>
          <div v-else >
            <v-textarea
                rows="2"
                v-model="link"
                :value="link"
                label="Link"
            ></v-textarea>
          </div>


          <label class="mb-5 custom-label" >สีของสินค้า</label>
          <v-color-picker
              class="mt-5"
              v-model="color"
              :value="color"
              label="สี *"
          ></v-color-picker>

          <v-text-field
              v-model="color"
              label="สีของสินค้า *"
              :value="color"
              :rules="[v => !!v || 'กรุณาเลือกสีของสินค้า']"
              required
              outlined
          ></v-text-field>

          <v-text-field
              v-model="number"
              :value="number"
              label="ลำดับ"
          ></v-text-field>

          <v-row>
            <v-col>
              <v-file-input
                  @change="Preview_image"
                  :rules="[v => (is_edit || !!v) || 'กรุณาอัพโหลดรูป']"
                  accept="image/png"
                  placeholder="เลือก รูปภาพสินค้า"
                  v-model="cover_file"
                  :value="cover_file"
                  prepend-icon="mdi-camera"
                  label="รูปภาพสินค้า *"
              ></v-file-input>
              <div class="image_preview_container" align="center">
                <img class="img-preview" id="image_preview" src=""/>
              </div>
            </v-col>
            <v-col>
              <v-file-input
                  @change="Preview_imageActive"
                  accept="image/png"
                  placeholder="เลือก รูปภาพสินค้ารอง"
                  v-model="cover_file_active"
                  :value="cover_file_active"
                  prepend-icon="mdi-camera"
                  label="รูปภาพสินค้ารอง"
              ></v-file-input>
              <div class="image_preview_container" align="center">
                <img class="img-preview" id="image_preview_active" src=""/>
              </div>
            </v-col>
            <v-col>
              <v-file-input
                  @change="Preview_imageText"
                  accept="image/png"
                  placeholder="เลือก รูปภาพ label สินค้า"
                  v-model="file_text_image"
                  :value="file_text_image"
                  prepend-icon="mdi-camera"
                  label="รูปภาพ label สินค้า"
              ></v-file-input>
              <div class="image_preview_container image_preview_container_text" align="center">
                <img class="img-preview" id="image_preview_text" src=""/>
              </div>
            </v-col>
          </v-row>



          <v-row>
            <v-file-input
                @change="previewMultiImage"
                id="my-file"
                accept="image/*" multiple="multiple"
                placeholder="เลือก รูปภาพเพิ่มเติม"
                prepend-icon="mdi-camera"
                label="รูปภาพเพิ่มเติม"
            ></v-file-input>
          </v-row>

          <div class="border p-2 mt-3">
            <template v-if="preview_list.length">
              <div class="preview-images">
                <div class="img-preview-container" v-for="item, index in preview_list" :key="index">
                  <img :src="item" class="img-fluid"/>
                  <p class="mb-0">file name: {{ image_list[index].name }}</p>
                  <p v-if="((image_list[index].size / 1024)/1024) < 2" >size: {{ ((image_list[index].size / 1024)/1024).toFixed(2) }} MB</p>
                  <p v-else style="color: red" >size: {{ ((image_list[index].size / 1024)/1024).toFixed(2) }} MB (ขนาดไฟล์ต้องไม่เกิน 2 MB)</p>
                  <button type="button" class="close_btn" @click="DeleteImage(index)">X</button>
                </div>
              </div>

            </template>
          </div>

          <div class="border p-2 mt-3">
            <template v-if="gallery.length">
              <div class="preview-images">
                <div class="img-preview-container" v-for="item, index in gallery" :key="index">
                  <img :src="baseUrlMain()+'/'+JSON.parse(item.data).path" class="img-fluid"/>
                  <button type="button" class="close_btn" @click="DeleteImageGallery(index,item.id)">X</button>
                </div>
              </div>

            </template>
          </div>


          <v-checkbox
              v-model="is_youtube"
              :value="is_youtube"
              label="เปิดใช้วีดีโอ Youtube"
          ></v-checkbox>


          <v-textarea
              v-if="is_youtube"
              :rules="youtube_rules"
              v-model="youtube"
              :value="youtube"
              v-on:keyup="CheckYoutube"
              label="ลิ้ง Youtube *"
          ></v-textarea>

          <div v-if="youtubePreview && is_youtube" class="image_preview_container" align="center">
            <iframe width="560" height="315"
                    :src="youtubePreview"
                    title="YouTube Player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
            </iframe>
          </div>


       <!--  <v-row v-if="is_youtube" >
             <v-col>
               <v-file-input
                   @change="Preview_VideoCover"
                   :rules="[v => (is_edit && youtubePreview || !!v) || 'กรุณาอัพโหลดรูปปกวีดีโอ']"
                   accept="image/*"
                   placeholder="เลือก รูปภาพปกวีดีโอ"
                   v-model="cover_video_file"
                   :value="cover_video_file"
                   prepend-icon="mdi-camera"
                   label="รูปปกวีดีโอ *"
               ></v-file-input>
               <div class="image_preview_container" align="center">
                 <img class="img-preview" id="image_preview_video_cover" :src="videoCover"/>
               </div>
             </v-col>
           </v-row> -->


           <div v-if="!is_loading" align="center" style="margin-top: 2rem">
             <buttons
                 class="ml-2 mr-2"
             :title="id != 0 ? 'บันทึก':'เพิ่มข้อมูล'"
             type="primary"
             :cb="validate"
             />
             <buttons
                 class="ml-2 mr-2"
                 title="ยกเลิก"
                 type="danger"
                 :cb="reset"
             />
             <buttons
                 class="ml-2 mr-2"
                 v-if=" id != 0 "
                 title="ยกเลิกแก้ไข"
                 type="danger"
                 :cb="cancle"
             />
           </div>
           <div v-if="is_loading" class="progress-loading-container" >
             <div class="progress-loading" :style="{ width: progress+'%' }" id="progressLoading" >
             </div>
           </div>
         </v-form>
       </div>

       <div class="form_table" >

         <div class="list_button" >
           <button class="btn-lang" @click="lang = 'all' " :class="{ 'btn-lang-active': lang === 'all' }">All</button>
           <button class="btn-lang" @click="lang = 'th' " :class="{ 'btn-lang-active': lang === 'th' }">TH</button>
           <button class="btn-lang" @click="lang = 'en' " :class="{ 'btn-lang-active': lang === 'en' }">EN</button>
         </div>

         <div v-if="lang == 'en' " class="list_button mt-2" >
           <button class="btn-lang" @click="cat = 'us' " :class="{ 'btn-lang-active': cat === 'us' }">USA</button>
           <button class="btn-lang" @click="cat = 'ko' " :class="{ 'btn-lang-active': cat === 'ko' }">Korea</button>
           <button class="btn-lang" @click="cat = 'bo' " :class="{ 'btn-lang-active': cat === 'bo' }">Bosnia</button>
           <button class="btn-lang" @click="cat = 'fr' " :class="{ 'btn-lang-active': cat === 'fr' }">France</button>
           <button class="btn-lang" @click="cat = 'ch' " :class="{ 'btn-lang-active': cat === 'ch' }">China</button>
         </div>

         <v-data-table
             :headers="headers"
             :items="resultData"
             :options.sync="options"
              sortBy="number"
             :server-items-length="totalItems"
             :loading="loading"
             class="elevation-1"
         >
           <template v-slot:top>
           </template>
           <template v-slot:item.cover="{ item }">
             <div class="box-container" >
             <img v-if="item.cover" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.cover).path" />
             <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
               <div class="box-color" :style="{ background: item.color }" ></div>
             </div>
           </template>

           <template v-slot:item.cover_active="{ item }">
             <img v-if="item.cover_active" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.cover_active).path" />
             <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
           </template>

           <template v-slot:item.text_image="{ item }">
             <div class="container-img" >
               <img v-if="item.text_image" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.text_image).path" />
               <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
             </div>

           </template>

           <template v-slot:item.created_at="{ item }">
             {{  dateTime(item.created_at) }}
           </template>

           <template v-slot:item.updated_at="{ item }">
             {{ dateTime(item.updated_at) }}
           </template>

           <template v-slot:item.actions="{ item }">

             <div class="list-icon" >
               <v-icon
                   small
                   class="mr-2 icon-list green-icon"
                   @click="editItem(item)"
               >
                 mdi-pencil
               </v-icon>

               <v-icon
                   small
                   class="mr-2 icon-list red-icon"
                   @click="deleteItem(item.id)"
               >
                 mdi-trash-can
               </v-icon>
             </div>


           </template>
           <template v-slot:no-data>
             <v-btn
                 color="primary"
                 @click="GetData()"
             >
               Reset
             </v-btn>
           </template>

         </v-data-table>
       </div>

     </div>

   </v-container>
 </template>
 <script>
 // @ is an alias to /src
 //import {} from "../lib/controller";
 // import {ResponseData} from "../lib/utility";
 import {baseUrl, baseUrlMain} from "../const/api";
 import $ from 'jquery'

 import { UpdateProduct, GetProduct, MediaRemove, DeleteProduct} from "../lib/controller";
 import {dateTime, ResponseData} from "../lib/utility";
 import Buttons from "../components/Buttons/index.vue";

 import moment from "moment";
 import 'moment/locale/th';


 const now = moment();
 console.log(now.format('LLLL'));

 export default {
   name: 'Product',
   components: {Buttons},
   data: () => ({
     items: [
       {
         text: 'Home',
         disabled: false,
         href: '/',
       },
     ],
     headers: [
       {text: 'ภาพปก',key:'id', value: 'cover',width:'50'},
       {text: 'ภาพรอง',key:'id', value: 'cover_active',width:'50'},
       {text: 'ภาพตัวหนังสือ',key:'id', value: 'text_image',width:'50'},
       {text: 'ชื่อ', value: 'title',width:'100'},
       {text: 'ลำดับ',key:'number',  value: 'number',width:'100'},
       {text: 'ภาษา',key:'lang',  value: 'lang',width:'100'},
       {text: 'ประเทศ',key:'category',  value: 'category',width:'100'},
       {text: 'วันที่เพิ่ม',key:'created_at',  value: 'created_at',width:'50'},
       {text: 'วันที่อัพเดท',key:'updated_at',  value: 'updated_at',width:'50'},
       {text: 'Actions', value: 'actions', sortable: false,width:'10'},
     ],
     resultData: [],
     is_loading:false,
     valid: true,
     title: "",
     color: "",
     description: "",
     youtubePreview:"",
     id: 0,
     pin: "",
     show1: false,
     show4: false,
     image_preview: "",
     edit: "",
     nickname: "",
     shop: "",
     laz: "",
     lang: "all",
     cat: "us",
     language: "th",
     category: "us",
     cover_file: "",
     cover_file_active: "",
     cover_video_file: "",
     file_text_image: "",
     video: "",
     videoUrl: "",
     number: 0,
     youtube_rules: [
       v => !!v || 'กรุณาเพิ่มลิ้ง Youtube',
       v => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(v) || 'รูปแบบลิ้งไม่ถูกต้อง',
     ],
     videotype: "",
     videoType: [],
     youtube: "",
     first_name: "",
     fb: "",
     ig: "",
     x: "",
     yt: "",
     tiktok: "",
     link: "",
     last_name: "",
     tel: "",
     progress: 0,
     address: "",
     email: "",
     preview_list: [],
     image_list: [],
     is_admin: 0,
     is_edit: false,
     is_youtube: false,
     is_en: false,
     baseUrl: baseUrl,
     password: '',
     password_confirm: '',
     itemsPerPage: 5,
     search: '',
     loading: true,
     videoCover: '',
     gallery: {},
     totalItems: 0,
     options: {},
     email_rules: [
       v => !!v || 'กรุณากรอกอีเมล',
       v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
     ],
     PinRule: [
       (v) => !!v || "กรุณากรอก PIN",
       (v) =>
           (v && v.length <= 4) ||
           "PIN ต้องไม่เกิน 4 ตัว",
     ],
   }),
   watch: {
     options: {
       handler () {
         this.GetData()
       },
       deep: true,
     },
     lang(){
       this.GetData();
     },
     cat(){
       this.GetData();
     }
   },
   methods:{
     dateTime,
     DeleteImage(index) {
       this.image_list.splice(index, 1);
       this.preview_list.splice(index, 1);
     },
     previewMultiImage: function (event) {

       //this.preview_list = [];
       //this.image_list = [];

       console.warn(event);

       event.forEach((file) => {

         var reader = new FileReader();

         reader.onload = (e) => {
           // Push the read data (base64 string) into 'preview_list'
           this.preview_list.push(e.target.result);
         };


         reader.readAsDataURL(file);
         this.image_list.push(file);
       });

     },
     async DeleteImageGallery(index, id) {


       await MediaRemove(id, (response) => {
         const {status, data} = ResponseData(response)
         switch (status) {
           case 200:
             console.warn(data);
             this.gallery.splice(index, 1);
             break;
           default:
             break;
         }

       });
     },
     baseUrlMain() {
       return baseUrlMain
     },
     Preview_image(e) {
       if (e) {
         this.cover_file = e;
         $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
       }
     },
     Preview_imageActive(e) {
       if (e) {
         this.cover_file_active = e;
         $('#image_preview_active').attr('src', URL.createObjectURL(e)); // jQuery selector
       }
     }
     ,Preview_imageText(e) {
       if (e) {
         this.file_text_image = e;
         $('#image_preview_text').attr('src', URL.createObjectURL(e)); // jQuery selector
       }
     },
     Preview_VideoCover(e) {
       if (e) {
         this.cover_video_file = e;
         this.videoCover = URL.createObjectURL(e);
         //$('#image_preview_video_cover').attr('src', URL.createObjectURL(e)); // jQuery selector
       }
     },
     Preview_video(file) {
       if (file) {
         this.video = file;
         const reader = new FileReader();

         reader.readAsDataURL(file);
         reader.onload = () => { // Use arrow function to keep 'this' context
           const videoPreview = this.$refs.videoPreview;
           this.videoUrl = reader.result;
           //videoPreview.src = reader.result;
           videoPreview.load(); // Load the new video source
           videoPreview.play(); // Optional: play the video
         };
       }
     },
     validate() {
       if (this.$refs.form.validate()) {
         this.Update();
       }
     },
     reset () {
       this.$refs.form.reset()
       this.image_list = [];
       this.preview_list = [];
       $('#image_preview').attr('src','');
       $('#image_preview_active').attr('src','');
       $('#image_preview_text').attr('src','');
       $('#image_preview_video_cover').attr('src','');
       this.color = "#FD9D0AFF";
       this.number = 1;
       this.id = 0;
     },
     resetEdit (data) {
       this.image_list = [];
       this.preview_list = [];
       $('#image_preview').attr('src','');
       $('#image_preview_active').attr('src','');
       $('#image_preview_text').attr('src','');
       $('#image_preview_video_cover').attr('src','');
       this.color = "#FD9D0AFF";
       this.id = 0;
       this.number = 0;
       this.editItem(data);
       this.GetData();
     },
     goBack(){
       this.$router.push({ path: '/manage/'})
     },
     CheckYoutube() {

       const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
       if (youtubeRegex.test(this.youtube)) {
         const url = new URL(this.youtube);
         let videoId;
         if (url.hostname === 'youtu.be') {
           videoId = url.pathname.slice(1);
         } else {
           videoId = url.searchParams.get('v');
         }
         if (videoId) {
           this.youtubePreview = `https://www.youtube.com/embed/${videoId}`;
           this.error = null;
         } else {
           this.error = 'Invalid YouTube URL: Video ID not found.';
           this.youtubePreview = '';
         }
       } else {
         this.error = 'Invalid YouTube URL format.';
         this.youtubePreview = '';
       }

       /*const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]+(\?.*)?$/;
       if (youtubeRegex.test(this.youtube)) {
         this.youtubePreview = this.getYoutubePreview(this.youtube);
       } else {
         this.youtubePreview = "";
       }*/
     },
     getYoutubePreview(url) {
       const urlObj = new URL(url);
       let videoId = "";

       if (urlObj.hostname === "youtu.be") {
         videoId = urlObj.pathname.substring(1);
       } else if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
         videoId = urlObj.searchParams.get('v');
       }

       return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
     },
     cancle () {
       this.$refs.form.reset()
       this.id = 0;
       this.is_edit = false;
     },
     async Update(){

       this.is_loading = true;

       let formData = new FormData();



       formData.append("youtube",this.is_youtube);
       formData.append("title",this.title);
       formData.append("description",this.description);
       if(this.cover_file){
         formData.append("cover_file",this.cover_file);
       }

       if(this.cover_file_active){
       formData.append("cover_file_active",this.cover_file_active);
       }
       if(this.file_text_image){
         formData.append("file_text_image",this.file_text_image);
       }

       formData.append("lang",this.language);

       if(this.language == 'en'){
         formData.append("category",this.category);
       }

       if(this.cover_video_file){
       formData.append("cover_video_file",this.cover_video_file);
       }

       if(this.is_youtube){
         formData.append("url",this.youtubePreview);
       }else{
         formData.append("url",'');
         formData.append("video_cover",'');
       }

       formData.append("video_file",this.video);
       formData.append("number",this.number);
       formData.append("color",this.color);



       formData.append("fb",this.fb);
       formData.append("ig",this.ig);
       formData.append("x",this.x);
       formData.append("shop",this.shop);
       formData.append("yt",this.yt);
       formData.append("laz",this.laz);
       formData.append("tiktok",this.tiktok);
       formData.append("link",this.link);


       this.image_list = this.image_list.filter(res => ((res.size / 1024) / 1024) < 2);

       this.image_list.forEach((file) => {
         formData.append('gallery_image[]', file);
       });


       if(this.id != 0){
         formData.append('is_edit',this.id)
       }

       await UpdateProduct(formData, (response) => {
         console.warn(response);
         const {status, data} = ResponseData(response)
         this.is_loading = false;
         switch (status) {
           case 200:

             this.$swal({
               title: "สำเร็จ",
               text: this.id ? "อัพเดทสำเร็จ" : "เพิ่มข้อมูลสำเร็จ" ,
               icon: "success",
               confirmButtonText: "ตกลง"
             }).then(async () => {
               if(this.id){
                 this.resetEdit(data);
               }else{
                 this.reset();
                 await this.GetData();
               }

             });


             break;

           case 422 :

             for (const [key, value] of Object.entries(data)) {
               console.log(`${key}: ${value}`);

               this.$toast.open({
                 message: `${value}`,
                 type: "warning",
                 duration: 10000,
                 dismissible: true
               })

             }

             break;

           default:


             break;
         }

       }, (UploadEvent) => {
         if (UploadEvent.total > 0) { // Avoid division by zero
           this.progress = (UploadEvent.loaded / UploadEvent.total) * 100;
         }

         // Reset progress if it's complete
         if (this.progress >= 100) {
           this.progress = 0;
         }
         console.warn(this.progress);
       })



     },

     editItem(item){


       this.id = item.id;
       this.is_edit = true;
       console.log(item)
       this.title = item.title;
       this.description = item.description;
       this.fb = item.fb ? item.fb : '';
       this.ig = item.ig ? item.ig : '';
       this.x = item.x ? item.x : '';
       this.yt = item.yt ? item.yt : '';
       this.tiktok = item.tiktok ? item.tiktok : '';
       this.link = item.link ? item.link : '';
       this.number = item.number;
       this.laz = item.laz ? item.laz : '';
       this.shop = item.shop ? item.shop : '';
       this.color = item.color;
       this.category = item.category;




       this.is_youtube = !item.url  ? false : true;
       this.language = item.lang;

       if(item.url && this.is_youtube){
         const url = new URL(item.url);
         let videoId;

         if (url.pathname.includes('/embed/')) {
           videoId = url.pathname.split('/embed/')[1];
           this.youtube = `https://www.youtube.com/watch?v=${videoId}`;
           this.youtubePreview = item.url;
         }
       }






       if(item.video_cover){
         this.videoCover = this.baseUrlMain()+'/'+JSON.parse(item.video_cover).path;
       }
       if(item.cover) {
         $('#image_preview').attr('src', this.baseUrlMain() + '/' + JSON.parse(item.cover).path);
       }
       if(item.cover_active) {
         $('#image_preview_active').attr('src', this.baseUrlMain() + '/' + JSON.parse(item.cover_active).path);
       }
       if(item.text_image) {
         $('#image_preview_text').attr('src', this.baseUrlMain() + '/' + JSON.parse(item.text_image).path);
       }





       this.gallery = item.gallery;

       if(this.url){
         this.youtubePreview = item.url
       }

       if(this.video){

         //console.warn(this.baseUrlMain()+'/'+JSON.parse(item.media.data).path);
         this.video = true;
         const videoPreview = this.$refs.videoPreview;
         this.videoUrl = this.baseUrlMain()+'/'+JSON.parse(item.video.data).path;
         videoPreview.load(); // Load the new video source
         videoPreview.play(); // Optional: play the video
       }


       window.scrollTo({ top:100, behavior: 'smooth' });

     },
     previewVideoCover(path){
       $('#image_preview_video_cover').attr('src',this.baseUrlMain()+'/'+path);
     },
     async GetData(){

       const { sortBy, sortDesc, page, itemsPerPage } = this.options

       const params = {
         page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
       }

       if(this.lang != 'all'){
         params.lang = this.lang;
         if(this.lang == 'en'){
         params.category = this.cat;
         }
       }

       console.warn(params)
       this.loading = true;

       console.log(this)
       await GetProduct(params,(response)=>{
         const {status, data} = ResponseData(response)
         this.loading = false;
         switch (status){
           case 200:
             this.resultData = data.data;
             this.totalItems = data.total;
             this.itemsPerPage = data.to;
             break;
           default:
             break;
         }

       });
     },
     async deleteItem(id){
       console.log(id)
       this.$swal({
         title: 'ต้องการลบใช่หรือไม่ ?',
         showCancelButton: true,
         confirmButtonText: 'ตกลง',
         cancelButtonText:"ยกเลิก",
       }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
           this.delete(id)
         }
       })

       ;
     },
     async delete(id){
       let Swal = this.$swal;

       await DeleteProduct(id,async  (response)=>{
         const {status, data} = ResponseData(response)
         console.log(data);
         switch (status){
           case 200:
             Swal({
               icon: 'success',
               title: 'Success',
             })

             await this.GetData()

             break;
           default:
             break;
         }

       });

     },
   },
   async mounted() {
    await this.GetData();
   }
 }
 </script>
 <style>

 .list_item img{
   width: 60%;
 }
 .list_item font{
   font-size: 1.5rem;
   color: black;
 }
 .container{
   height: 100%;
 }
 .img-logo{
   width: 30rem;
 }
 .v-main__wrap{
   height: 100%;
 }
 .v-main{
   height: 100%;
 }
 .ip_custom{
   opacity: 0;
 }
 .progress-loading-container{
   width: 100%;
   height: 2rem;
   background: #ffffff;
   margin-top: 1rem;
   margin-bottom: 1rem;
   border-radius: 10px;
   overflow: hidden;
   border: 1px #c9c9c9 solid;
   position: relative;
 }
 .progress-val{
   color: #151515;
   z-index: 5;
   font-size: 1.2rem;
 }
 .progress-loading{
   width: 0%;
   height: 100%;
   background: #FDD44D;
 }
 .list-icon{
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .icon-list{
   padding-left: 0;
   padding-right: 0;
   padding: 0.2rem;
   width: 2rem;
   height: 2rem;
   font-size: 1.2rem !important;
 }
 .custom-label{
   color: #6b6b6b;
 }
 .image_preview_container_text{
   background: #6a737d;
   padding: 1rem;
 }
 .img-preview{
   max-width: 100% !important;
 }
 .container-img{
   background: #6a737d;
 }
 .box-container{
   position: relative;
   display: flex;
   justify-content: center;
   padding-bottom: 1rem;
 }
 .box-container .img_thumb{
   position: relative;
   z-index: 2;
 }
 .box-color{
   width: 100%;
   height: 4rem;
   background: red;
   position: absolute;
   bottom: 0;
 }
 .v-list-item__content .v-list-item__title{

 }
 #image_preview_video_cover{
   max-width: 30rem !important;
 }

 </style>
