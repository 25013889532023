<template>
  <v-container class="custom-container container pt-8" >
    <div class="list_form_card pl-5" >
      <h2>FAQ</h2>
      <div class="form_card mt-5" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <div class="lang-list" >
            <v-checkbox
                v-model="language"
                value="th"
                label="TH"></v-checkbox>

            <v-checkbox
                v-model="language"
                value="en"
                label="EN"></v-checkbox>
          </div>

          <v-text-field
              v-model="title"
              label="ชื่อFAQ *"
              :value="title"
              :rules="[v => !!v || 'กรุณาตั้งชื่อชื่อFAQ']"
              required
              outlined
          ></v-text-field>



          <v-textarea
              v-model="description"
              :value="description"
              :rules="[v => !!v || 'กรุณากรอกคำอธิบาย']"
              label="คำอธิบาย *"
              outlined
          ></v-textarea>



          <v-text-field
              v-model="number"
              :value="number"
              label="ลำดับ"
          ></v-text-field>


          <div v-if="!is_loading" align="center" style="margin-top: 2rem">
            <buttons
                class="ml-2 mr-2"
            :title="id != 0 ? 'บันทึก':'เพิ่มข้อมูล'"
            type="primary"
            :cb="validate"
            />
            <buttons
                class="ml-2 mr-2"
                title="ยกเลิก"
                type="danger"
                :cb="reset"
            />
            <buttons
                class="ml-2 mr-2"
                v-if=" id != 0 "
                title="ยกเลิกแก้ไข"
                type="danger"
                :cb="cancle"
            />
          </div>
          <div v-if="is_loading" class="progress-loading-container" >
            <div class="progress-loading" :style="{ width: progress+'%' }" id="progressLoading" >
            </div>
          </div>
        </v-form>
      </div>

      <div class="form_table" >

        <div class="list_button" >
          <button class="btn-lang" @click="lang = 'all' " :class="{ 'btn-lang-active': lang === 'all' }">All</button>
          <button class="btn-lang" @click="lang = 'en' " :class="{ 'btn-lang-active': lang === 'en' }">EN</button>
          <button class="btn-lang" @click="lang = 'th' " :class="{ 'btn-lang-active': lang === 'th' }">TH</button>
        </div>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :options.sync="options"
             sortBy="id"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.cover="{ item }">
            <img v-if="item.cover" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.cover).path" />
            <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{  dateTime(item.created_at) }}
          </template>

          <template v-slot:item.updated_at ="{ item }">
            {{ dateTime(item.updated_at) }}
          </template>

          <template v-slot:item.actions="{ item }">

            <div class="list-icon" >
              <v-icon
                  small
                  class="mr-2 icon-list green-icon"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  class="mr-2 icon-list red-icon"
                  @click="deleteItem(item.id)"
              >
                mdi-trash-can
              </v-icon>
            </div>


          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl, baseUrlMain} from "../const/api";
import $ from 'jquery'

import {UpdateFaq, GetFaq, DeleteFaq} from "../lib/controller";
import {dateTime, ResponseData} from "../lib/utility";
import Buttons from "../components/Buttons/index.vue";

export default {
  name: 'FAQ',
  components: {Buttons},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อ', value: 'title',width:'100'},
      {text: 'คำอธิบาย',key:'description',  value: 'description',width:'100'},
      {text: 'ลำดับ',key:'number', value: 'number',width:'100'},
      {text: 'ภาษา',key:'lang',  value: 'lang',width:'100'},
      {text: 'วันที่เพิ่ม',key:'created_at',  value: 'created_at',width:'50'},
      {text: 'วันที่อัพเดท',key:'updated_at',  value: 'updated_at',width:'50'},
      {text: 'Actions', value: 'actions', sortable: false,width:'10'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    title: "",
    title_en: "",
    description: "",
    description_en: "",
    youtubePreview:"",
    id: 0,
    number: 0,
    pin: "",
    lang: "all",
    show1: false,
    show4: false,
    is_en: false,
    image_preview: "",
    edit: "",
    nickname: "",
    cover_file: "",
    cover_file_en: "",
    cover_video_file: "",
    video: "",
    language: "th",
    videoUrl: "",
    orders: "",
    youtube_rules: [
      v => !!v || 'กรุณาเพิ่มลิ้ง Youtube',
      v => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(v) || 'รูปแบบลิ้งไม่ถูกต้อง',
    ],
    videotype: "",
    videoType: [],
    youtube: "",
    first_name: "",
    fb: "",
    ig: "",
    x: "",
    yt: "",
    tiktok: "",
    last_name: "",
    tel: "",
    progress: 0,
    address: "",
    email: "",
    preview_list: [],
    image_list: [],
    is_admin: 0,
    is_edit: false,
    is_youtube: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    itemsPerPage: 5,
    search: '',
    loading: true,
    gallery: {},
    totalItems: 0,
    options: {},
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
    lang(){
      this.GetData();
    }
  },
  methods:{
    dateTime,
    DeleteImage(index) {
      this.image_list.splice(index, 1);
      this.preview_list.splice(index, 1);
    },
    previewMultiImage: function (event) {

      //this.preview_list = [];
      //this.image_list = [];

      console.warn(event);

      event.forEach((file) => {

        var reader = new FileReader();

        reader.onload = (e) => {
          // Push the read data (base64 string) into 'preview_list'
          this.preview_list.push(e.target.result);
        };


        reader.readAsDataURL(file);
        this.image_list.push(file);
      });

    },
    baseUrlMain() {
      return baseUrlMain
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
    },
    resetEdit (data) {
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
      this.editItem(data);
      this.GetData();
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    CheckYoutube(){
      if(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(this.youtube)){
        this.youtubePreview = this.getYoutubePreview(this.youtube)
      }else{
        this.youtubePreview = "";
      }
    },
    getYoutubePreview(url) {
      const urlObj = new URL(url);
      this.youtubeCode = urlObj.searchParams.get('v');
      return "https://www.youtube.com/embed/"+urlObj.searchParams.get('v');
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      this.is_loading = true;

      let formData = new FormData();


      formData.append("title",this.title);
      formData.append("description",this.description);
      formData.append("number",this.number);

      formData.append("lang",this.language);

      if(this.id != 0){
        formData.append('is_edit',this.id)
      }

      await UpdateFaq(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        this.is_loading = false;
        switch (status) {
          case 200:

            this.$swal({
              title: "สำเร็จ",
              text: this.id ? "อัพเดทสำเร็จ" : "เพิ่มข้อมูลสำเร็จ" ,
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              if(this.id){
                this.resetEdit(data);
              }else{
                this.reset();
                await this.GetData();
              }

            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      }, (UploadEvent) => {
        if (UploadEvent.total > 0) { // Avoid division by zero
          this.progress = (UploadEvent.loaded / UploadEvent.total) * 100;
        }

        // Reset progress if it's complete
        if (this.progress >= 100) {
          this.progress = 0;
        }
        console.warn(this.progress);
      })



    },

    editItem(item){


      this.id = item.id;
      this.is_edit = true;
      console.log(item)
      this.title = item.title;
      this.description = item.description;
      this.number = item.number;
      this.language = item.lang;

      window.scrollTo({ top:100, behavior: 'smooth' });


    },
    async GetData(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }

      if(this.lang != 'all'){
        params.lang = this.lang;
      }

      console.warn(params)
      this.loading = true;

      console.log(this)
      await GetFaq(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loading = false;
        switch (status){
          case 200:
            this.resultData = data.data;
            this.totalItems = data.total;
            this.itemsPerPage = data.to;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteFaq(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
.progress-loading-container{
  width: 100%;
  height: 2rem;
  background: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  border: 1px #c9c9c9 solid;
  position: relative;
}
.progress-val{
  color: #151515;
  z-index: 5;
  font-size: 1.2rem;
}
.progress-loading{
  width: 0%;
  height: 100%;
  background: #FDD44D;
}
.list-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-list{
  padding-left: 0;
  padding-right: 0;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem !important;
}

</style>
