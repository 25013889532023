import {API,instance} from "../const/api";



export async function Auth(param, Calback) {
    await instance.post(API.Auth, param,{
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}
