<template>
  <div class="login_container" >
    <div class="form_login" >
      <div align="center" >
      <img class="logo_image" src="../assets/image/logo.png">
      </div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="username"
            :rules="email_rules"
            label="อีเมล"
            required
        ></v-text-field>

        <v-text-field
            v-model="password"
            :rules="[rules.required, rules.min]"
            :type="show_password ? 'text' : 'password'"
            name="input-10-1"
            label="รหัสผ่าน"
            @click:append="show_password = !show_password"
        ></v-text-field>

        <div align="center" style="margin-top: 2rem" >
          <Buttons class="ml-2 mr-2"
                   title="Login"
                   type="primary"
                   :cb="Login"
          />
          <Buttons class="ml-2 mr-2"
                   title="Reset"
                   type="danger"
                   :cb="reset"
          />
          <br><br>
          <font v-if="notfound" class="notfound" >ไม่พบชื่อผู้ใช้งาน</font>
        </div>

      </v-form>



    </div>
  </div>
</template>
<script>
// @ is an alias to /src

//import $ from "jquery";

import {Auth} from "../lib/controller_auth";
import {ResponseData} from "../lib/utility";
import  Buttons  from '../components/Buttons/index.vue'

export default {
  name: 'Login',
  components:{
    Buttons
  },
  data: () => ({
    password: '',
    username: '',
    valid: true,
    notfound: false,
    show_password: false,
    rules: {
      required: value => !!value || 'กรอกรหัสผ่าน.',
    },
    email_rules: [
      v => !!v || 'อีเมลต้องไม่เป็นค่าว่าง',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
  }),

  methods:{
    validate () {
      this.notfound = false;
      if(this.$refs.form.validate()){
        this.Login();
      }
    },
    async Login(){


      const param = {
        "username":this.username,
        "password":this.password,
      }

      await Auth(param,(response)=>{
        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            localStorage.setItem('user',JSON.stringify(data.data))
            localStorage.setItem('token',JSON.stringify(data.token))

            this.$swal({
              title: "เข้าสู่ระบบสำเร็จ",
              text: "ยินดีต้อนรับ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              window.location = "/"
            });

            break;
          case 404:
            this.$swal({
              title: "เข้าสู่ระบบไม่สำเร็จ",
              text: "ไม่พบข้อมูลผู้ใช้งาน",
              icon: "warning",
              confirmButtonText: "ตกลง"
            }).then(async () => {
            });
            break;
        }

      });
    },
    reset () {
      this.$refs.form.reset()
    },
    CheckLogin(){
      const UserData = localStorage.getItem('user');
      if(UserData){
        console.log("Islogin")
        //window.location = "/"
      }else{
        //console.log("Nologin")
      }
    }
  },
  mounted() {
    this.CheckLogin();
  }
}
</script>
<style>
.v-application .error{
  background-color: #ff0e59 !important;
  border-color: #fd1e63 !important;
}
</style>
