<template>
  <v-container class="custom-container container pt-8" >
    <div class="list_form_card pl-5" >
      <h2>การตั้งค่า (Contact)</h2>
      <div class="form_card mt-5" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-row>
            <v-col>
              <v-textarea
                  v-model="address"
                  :value="address"
                  :rules="[v => !!v || 'กรุณากรอกที่อยู่']"
                  label="ที่อยู่ *"
                  outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-textarea
                  v-model="address_en"
                  :value="address_en"
                  :rules="[v => !!v || 'กรุณากรอกที่อยู่']"
                  label="ที่อยู่ EN *"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  v-model="tel"
                  label="เบอร์โทร *"
                  :value="tel"
                  :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                  required
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  v-model="tel_en"
                  label="เบอร์โทร EN *"
                  :value="tel_en"
                  :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                  required
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col>

          <v-text-field
              v-model="email"
              label="อีเมล *"
              :value="email"
              :rules="[v => !!v || 'กรุณากรอกอีเมล']"
              required
              outlined
          ></v-text-field>
            </v-col>
            <v-col>

              <v-text-field
                  v-model="email_en"
                  label="อีเมล EN *"
                  :value="email_en"
                  :rules="[v => !!v || 'กรุณากรอกอีเมล']"
                  required
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>

          <v-text-field
              v-model="open"
              label="เวลาเปิด-ปิด *"
              :value="open"
              :rules="[v => !!v || 'กรุณากรอกเวลาเปิด-ปิด']"
              required
              outlined
          ></v-text-field>
            </v-col>

            <v-col>

              <v-text-field
                  v-model="open_en"
                  label="เวลาเปิด-ปิด EN *"
                  :value="open_en"
                  :rules="[v => !!v || 'กรุณากรอกเวลาเปิด-ปิด']"
                  required
                  outlined
              ></v-text-field>
            </v-col>

          </v-row>

          <h2>การตั้งค่า (About)</h2>


          <v-row class="mt-8" >
            <v-col>
              <v-textarea
                  v-model="about"
                  :value="about"
                  :rules="[v => !!v || 'กรุณากรอกหัวข้อ about']"
                  label="หัวข้อ about *"
                  outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-textarea
                  v-model="about_en"
                  :value="about_en"
                  :rules="[v => !!v || 'กรุณากรอกหัวข้อ about EN']"
                  label="หัวข้อ about EN *"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                  v-model="about_1"
                  :value="about_1"
                  :rules="[v => !!v || 'กรุณากรอก about 1']"
                  label="about 1 *"
                  outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-textarea
                  v-model="about_1_en"
                  :value="about_1_en"
                  :rules="[v => !!v || 'กรุณากรอก about 1 EN']"
                  label="about 1 EN *"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                  v-model="about_2"
                  :value="about_2"
                  :rules="[v => !!v || 'กรุณากรอก about 2']"
                  label="about 2 *"
                  outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-textarea
                  v-model="about_2_en"
                  :value="about_2_en"
                  :rules="[v => !!v || 'กรุณากรอก about 2 EN']"
                  label="about 2 EN *"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <v-textarea
                  v-model="about_3"
                  :value="about_3"
                  :rules="[v => !!v || 'กรุณากรอก about 3']"
                  label="about 3 *"
                  outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-textarea
                  v-model="about_3_en"
                  :value="about_3_en"
                  :rules="[v => !!v || 'กรุณากรอก about 3 EN']"
                  label="about 3 EN *"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>


          <div v-if="!is_loading" align="center" style="margin-top: 2rem">
            <buttons
                class="ml-2 mr-2"
            title="บันทึก"
            type="primary"
            :cb="validate"
            />
            <buttons
                class="ml-2 mr-2"
                title="ยกเลิก"
                type="danger"
                :cb="reset"
            />
          </div>
        </v-form>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl} from "../const/api";
import $ from 'jquery'

import {DeleteNews, GetSetting, UpdateSetting} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import Buttons from "../components/Buttons/index.vue";

export default {
  name: 'Product',
  components: {Buttons},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อ', value: 'title',width:'100'},
      {text: 'คำอธิบาย',key:'description',  value: 'description',width:'100'},
      {text: 'ลำดับ',key:'number',  value: 'number',width:'100'},
      {text: 'ภาษา',key:'lang',  value: 'lang',width:'100'},
      {text: 'Actions', value: 'actions', sortable: false,width:'10'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    is_en: false,

    address: "",
    tel: "",
    email: "",
    open: "",
    address_en: "",
    tel_en: "",
    email_en: "",
    open_en: "",

    title_en: "",
    description: "",
    description_en: "",
    youtubePreview:"",
    id: 0,
    pin: "",
    show1: false,
    show4: false,
    image_preview: "",
    edit: "",
    lang: "all",
    language: "th",
    nickname: "",
    cover_file: "",
    cover_file_en: "",
    cover_video_file: "",
    video: "",
    videoUrl: "",
    number: 0,
    youtube_rules: [
      v => !!v || 'กรุณาเพิ่มลิ้ง Youtube',
      v => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(v) || 'รูปแบบลิ้งไม่ถูกต้อง',
    ],
    videotype: "",
    videoType: [],
    youtube: "",
    first_name: "",
    fb: "",
    ig: "",
    x: "",
    yt: "",
    tiktok: "",
    last_name: "",
    progress: 0,
    preview_list: [],
    image_list: [],
    is_admin: 0,
    is_edit: false,
    is_youtube: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    itemsPerPage: 5,
    search: '',
    loading: true,
    gallery: {},
    about: "",
    about_en: "",
    about_1: "",
    about_1_en: "",
    about_2: "",
    about_2_en: "",
    about_3: "",
    about_3_en: "",
    totalItems: 0,
    options: {},
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
    lang(){
      this.GetData();
    }
  },
  methods:{
    validate() {
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
    },
    resetEdit (data) {
      this.image_list = [];
      this.preview_list = [];
      $('#image_preview').attr('src','');
      $('#image_preview_en').attr('src','');
      this.id = 0;
      this.editItem(data);
      this.GetData();
    },
    getYoutubePreview(url) {
      const urlObj = new URL(url);
      this.youtubeCode = urlObj.searchParams.get('v');
      return "https://www.youtube.com/embed/"+urlObj.searchParams.get('v');
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      this.is_loading = true;

      let formData = new FormData();


      formData.append("address",this.address);
      formData.append("tel",this.tel);
      formData.append("email",this.email);
      formData.append("open",this.open);

      formData.append("address_en",this.address_en);
      formData.append("tel_en",this.tel_en);
      formData.append("email_en",this.email_en);
      formData.append("open_en",this.open_en);

      formData.append("about",this.about);
      formData.append("about_en",this.about_en);
      formData.append("about_1",this.about_1);
      formData.append("about_1_en",this.about_1_en);
      formData.append("about_2",this.about_2);
      formData.append("about_2_en",this.about_2_en);
      formData.append("about_3",this.about_3);
      formData.append("about_3_en",this.about_3_en);

      formData.append('id',this.id)


      await UpdateSetting(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        this.is_loading = false;
        switch (status) {
          case 200:

            this.$swal({
              title: "สำเร็จ",
              text: this.id ? "อัพเดทสำเร็จ" : "เพิ่มข้อมูลสำเร็จ" ,
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              if(this.id){
                this.resetEdit(data);
              }else{
                this.reset();
                await this.GetData();
              }

            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      }, (UploadEvent) => {
        if (UploadEvent.total > 0) { // Avoid division by zero
          this.progress = (UploadEvent.loaded / UploadEvent.total) * 100;
        }

        // Reset progress if it's complete
        if (this.progress >= 100) {
          this.progress = 0;
        }
        console.warn(this.progress);
      })



    },

    editItem(item){


      this.id = item.id;
      this.is_edit = true;
      console.log(item)
      this.title = item.title;
      this.description = item.description;
      this.language = item.lang;
      this.gallery = item.gallery;


    },

    async GetData(){


      const params = {}

      console.warn(params)
      this.loading = true;

      console.log(this)
      await GetSetting(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loading = false;
        switch (status){
          case 200:
            this.address = data.address;
            this.tel = data.tel;
            this.email = data.email;
            this.open = data.open;

            this.address_en = data.address_en;
            this.tel_en = data.tel_en;
            this.email_en = data.email_en;
            this.open_en = data.open_en;

            this.about = data.about;
            this.about_en = data.about_en;
            this.about_1 = data.about_1;
            this.about_1_en = data.about_1_en;
            this.about_2 = data.about_2;
            this.about_2_en = data.about_2_en;
            this.about_3 = data.about_3;
            this.about_3_en = data.about_3_en;


            this.id = data.id;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteNews(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
.progress-loading-container{
  width: 100%;
  height: 2rem;
  background: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  border: 1px #c9c9c9 solid;
  position: relative;
}
.progress-val{
  color: #151515;
  z-index: 5;
  font-size: 1.2rem;
}
.progress-loading{
  width: 0%;
  height: 100%;
  background: #FDD44D;
}
.list-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-list{
  padding-left: 0;
  padding-right: 0;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem !important;
}

</style>
